
import {computed, defineComponent, onMounted, ref} from "vue";
import {useTrainingStore} from "@/store/training.store";
import {getCurrentSpaceAccess, GetSpaceId, PinaFilterObjects} from "@/core/composite/composite";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import PageStatus from "@/views/doc/PageStatus.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Panel from "@/components/base/panel/Panel.vue";
import ShortPageForm from "@/views/doc/ShortPageForm.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2";
import DocUserService from "@/core/services/DocUserService.ts"

export default defineComponent({
  name: "AllTraining",
  components: {ShortPageForm, Panel, QuickAction, PageStatus, KtDatatable, SearchHorizontal},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Trainings",
        [
          {link: false, router: '', text: 'All Trainings'}
        ]
      )
    })
    const trainingStore = useTrainingStore();
    const filterObjects = ref({type: 'TRAINING'})

    const page = computed(() => trainingStore.page);
    const header = [
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },

      {
        name: 'Status',
        key: 'status',
        sortable: false
      },
      {
        name: '',
        key: 'actions',
        sortable: false,
      }
    ]
    const state = ref({title: '', show: false, id: null})
    const model = ref<any>({
      name: '',
      type: '',
      body: '',
      parentId: '',
      description: '',
      status: '',
      workspaceIdRequest: '',
      config: {allowFile: true, allowFaq: true}
    })
    return {
      state,
      page,
      header,
      model,
      ...GetSpaceId(),
      ...getCurrentSpaceAccess(),
      ...PinaFilterObjects(trainingStore, filterObjects.value, ['createdBy'])
    }
  },
  methods: {
    onStart(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocUserService.start(id).then(res => {
        this.$router.push(`/` + this.spaceId + "/training-lib/" + res.id);
      }).finally(() => {
        Swal.close();
      })
    },
    onClosePanel() {
      this.state.show = false;
    },
    onAdd(type) {
      this.model.name = '';
      this.model.type = type;
      this.model.body = '';
      this.model.status = 'DRAFT';
      this.model.description = ''
      this.state.title = 'Add Training'
      this.model.workspaceIdRequest = this.spaceId
      this.model.config = {allowFile: true, allowFaq: true}
      this.state.show = true
      this.state.id = null
    },
    onSavePage() {
      this.paginationDataLoad()
      this.onClosePanel()
    },
  }
})
