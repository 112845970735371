import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageStatus = _resolveComponent("PageStatus")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_SearchHorizontal = _resolveComponent("SearchHorizontal")!
  const _component_ShortPageForm = _resolveComponent("ShortPageForm")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchHorizontal, null, {
      actions: _withCtx(() => []),
      content: _withCtx(() => [
        _createVNode(_component_KtDatatable, {
          "table-data": _ctx.page.data,
          "table-header": _ctx.header,
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.filter.paginationPage,
          "rows-per-page": _ctx.filter.paginationSize,
          onItemsPerPageChange: _ctx.handleUpdateSize,
          onCurrentChange: _ctx.handleUpdatePage,
          total: _ctx.page.total
        }, {
          "cell-name": _withCtx(({ row: row }) => [
            _createVNode(_component_router_link, {
              to: `/`+_ctx.spaceId+`/training/` + row.id
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          "cell-status": _withCtx(({row: row}) => [
            _createVNode(_component_PageStatus, {
              status: row.status
            }, null, 8, ["status"])
          ]),
          "cell-actions": _withCtx(({row: row}) => [
            _createVNode(_component_QuickAction, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (row.status ==='PUBLISHED')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: "javascript:void(0);",
                        class: "menu-link px-3",
                        onClick: ($event: any) => (_ctx.onStart(row.id))
                      }, "Start Training", 8, _hoisted_2))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, {
      name: _ctx.state.title,
      show: _ctx.state.show,
      onClose: _ctx.onClosePanel
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ShortPageForm, {
              page: _ctx.model,
              id: _ctx.state.id,
              onClosePanel: _ctx.onSavePage
            }, null, 8, ["page", "id", "onClosePanel"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["name", "show", "onClose"])
  ], 64))
}